.Nav-text{
   
    margin-left: 2%;
}
.nav-body{
    font-size: large;
}
.nav-lastitem{
    margin-right: 30px ;
}
.navbarpic{
    height:50px;
    margin-left: 40px;
}

@media all and (max-width: 470px){
    .nav-body{
        /* justify-content: center; */
        /* width: 100%; */
    }
} 
