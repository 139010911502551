
.footer-body{
    background-color: grey;
    margin-top: 28%;
   padding:50px;
    height: 100%;
    width:100%
    

}
.col-title{
    font-size: xx-large;
    font-display:inherit;
    color: rgb(238, 199, 213);
    /* margin-right: px; */
    margin-left: 45px;
    margin-top: 200px;
}
.col-About-title{
    font-size: xx-large;
    font-display:inherit;
    margin-top: 1%;
    margin-left: 20%;
    color: rgb(238, 199, 213);
}
.footer-para1{
    margin-right: 65%;
    font-size: large;
    color:whitesmoke
}
.footer-para2{
    margin-left: 10%;
    font-size: large;
    color:whitesmoke;
    margin-top: 30px;
    /* margin-top: 10%; */
    
}
.footer-single-row{
    margin:0px;
    }

.footer-img{
    margin-left: 45%;
    height: 200%;
    width: auto;
   margin-right: 10%;
    margin-bottom: 8%;
    margin-top: 3rem;
  
}
/* .footer-licence{
    margin-left: 30%;
    color: whitesmoke;
} */

.footer-col-2{
    margin-left: 60%;
    margin-top: -24%;
    
}
.footer-col-1{
    margin-top: 3rem;
   
}
.footer-1{
    margin-left: 10%;
}
/* .heaading-footer{
    color: whitesmoke;
    margin-top: 3rem;
    text-align: center;
} */
.h-footer{
    color: white;
    text-align:whitesmoke;
    margin-top: 2rem;

}


/* @media all and (max-width :768px){
    .footer-body{
        width: 100%;
        margin: auto;
      
    }
    
    .footer-img{
    margin-right: 7px;
}


} */
@media all and (max-width: 370px){
.h-footer{
    margin-top: -50px;
    font-size: x-large;
    margin-left: 10%;
}
.col-About-title{
    font-size: x-large;
}
.col-title{
    font-size: x-large;
}
.footer-para1{
    font-size: x-large;
}
}

@media all and (max-width: 470px){
     .footer-img{
         width: auto;
         height: auto;
         margin-top: 5rem;
         margin-left: 27%;
         
     }
     .footer-body{
         width: auto;
         height: auto;
     }
     .footer-para1{
         font-size: medium;
         text-align:center;
         justify-content: center;
         margin: auto;
        
     }
     .footer-para2{
         margin-left: 15px;
     }
     .footer-col-2{
         margin-left: 5%;
         margin-top: 2rem;
     }
     .footer-1{
         margin-top: 4rem;
     } 
   
    
}

@media all and (max-width: 400px){


    .footer-img{
        width: auto;
        height: auto;
        margin-top: 50px;
        margin-top: 23px;
        margin-left: 10px;
    }
    .footer-body{
        width: auto;
        height: auto;

   
    }
    .footer-para1{
        font-size: medium;
        justify-content: center;
        text-align: center;
        margin-left: 10px;
        
    }
    .col-About-title{
        margin-right: 0px;
    }
    .footer-col-2{
        margin-top: 13px;
    }
    .footer-para2{
        margin-bottom: 50px;
    }
    .col-About-title{
        margin-left: 11%;
        margin-bottom: 8%;
    }
}

