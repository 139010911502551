
.board-design{
    width: 20rem;
    background-color: dimgray;
    
}
.text-field-design{
    color: whitesmoke;
}
.Ebcard{
    width: 40rem;
    margin-left: 22%;
    margin-top: 3%;
    /* margin-right: 5%; */
    border-radius: 45px;
    background-color:lightgrey;
}
.ebpic{
    height: 200px;
    width : 200px;
    text-align: center;
    justify-content: center;
    margin-left: 35%;
    margin-top: 10%;
    border-radius: 50%;
}

.card_descp{
    font-size: medium;
    font-family: 'Times New Roman';
    font-style: inherit;
}

.cardtitles{
    font-size: x-large;
    font-family: 'Times New Roman';
    font-style: inherit;
    text-align: center;
    margin-top: 3%;


    
}
.cardtitless{
    font-size: large;
    font-family: 'Times New Roman';
    font-style: inherit;
    text-align: center;
}

.Abouthim{
    font-size: x-large;
    font-family: 'Times New Roman';
    font-style: inherit;
    text-align: center;
    margin-top: 10%;

}
.card_info{
    font-size: medium;
    font-family: 'Times New Roman';
    font-style: inherit;
    text-align: left;
    margin-top: 1%;
    margin-left: 2%;
}
.heading{
    margin-top: 10%;
    font-family: 'Times New Roman';
    font-style: initial;
/* 
        background: #fff;
        border-radius: 5px;
        display: inline-block;
        height: 135px;
        margin: 1rem;
        position: relative;
        width: 90%;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
      
}

@media all and (max-width: 400px){
    .Ebcard {
        /* margin-right: 350%; */
        width: 20rem;
        margin-left: -9%;
        /* margin-right: 5%; */
        justify-content: center;
    }
    .ebpic{
        margin-right: 70px;
        height: 155px;
        width: 160px;
        margin-left: 25%;
    }
    .cardtitles{
        font-size: small;
    }
    .card_descp{
        font-size: small;
    }
    .card_info{
        font-size: small;
    }
    .heading{
        font-size: x-large;
    }

}

@media all and (max-width: 500px){
    .Ebcard {
        /* margin-right: 350%; */
        width: 23rem;
        margin-left: 0.5%;
        /* margin-right: 5%; */
        justify-content: center;
    }
    .ebpic{
        margin-right: 70px;
        height: 155px;
        width: 160px;
        margin-left: 25%;
    }
    .cardtitles{
        font-size: large;
    }
    .card_descp{
        font-size: small;
    }
    .card_info{
        font-size: small;
    }
    .heading{
        font-size: x-large;
    }

}