  .card-1 {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 135px;
    margin: 1rem;
    position: relative;
    width: 300px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }
  
  .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  .card-body{
    padding: 0px !important;
    margin : 4px !important;
  }
  

  .card-horizontal {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    height: 135px;
    margin: 1rem;
    position: relative;
    width: 90%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  }

  .card-horizontal:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
.journytitles{
  font-size: large;
  font-family: 'Times New Roman';
  font-style: initial;

}
.doi{
  font-size: large;
  font-family: 'Times New Roman';
  font-style: initial;
 text-align: left;

}