.home-img1{
width: 100%;
height : 120%



}
.home-img2{
    width: 100%;
    height : 120%
    

    }

    .home-img3{
        width: 100%;
        height : 120%
        
        }

.carousel-img{


             
                background-color:grey;
               
    }



@media all and (max-width: 400px){

    
    /* .home-img1{
        width: 25rem;
        
    } */
}

    /* .home-img2{
        width: 45%;
        margin-left: 10%;
        margin-bottom: 69px;
        margin-top: 5rem;
        
        
       }
       .home-img3{
        width: 45%;
        margin-left: 10%;
        margin-bottom: 69px;
        margin-top: 5rem;
        
       }
}

@media all and (max-width:470px){
    .home-img1{
        width: 45%;
        margin-left: 10%;
        margin-bottom: 69px;
        
    }
   .carousel-img{
    
    width: 114%;    
    
   }

   .home-img2{
    width: 45%;
    margin-left: 10%;
    margin-bottom: 69px;
    margin-top: 5rem;
    
    
   }

   .home-img3{
    width: 45%;
    margin-left: 10%;
    margin-bottom: 69px;
    margin-top: 5rem;
    
   }
} */


