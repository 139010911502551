.home-contents1{
    justify-content: center;
    margin-top: 4%;
    font-style:inherit;
    font-size:large;
    font-family:'Times New Roman';
}
.home-heading{
    margin-top: 13%;
    font-family: Verdana;
    text-align: center;
}
.home-issn{
    margin-top: 5rem;
    margin-left: 78%;

}
.card-header{
    background-color: dimgray;
    color: floralwhite;
    text-align: center;
    font-size:x-large;
    font-family:'Times New Roman';
}
.card-text{
    text-align: center;
    font-family:'Times New Roman';
    font-size: large;
    margin-top: 2rem;
}

.licence-img{
    width: 15%;
    margin-top: 5rem;
    margin-left: 45%;

}
.licence-line1{
    color: dimgray;
    margin-left: 45%;
    margin-top: 2rem;
}
.licence-line2{
    margin-left: 38%;
    margin-top: 1rem;
}
@media all and (max-width: 600px){
    .card-body{
        margin-bottom: 15%;
    }
    .card-text{
        margin-top: 2rem;
    }

}
@media all and (max-width : 470px){
    .home-contents1{
        margin-top:50px;
    }
    .home-heading{
        margin-top: 25%;
    }
    .home-issn{
        margin-left: 200px;
        font-size: small;
    }
    .licence-line1{
        margin-left: 20px;
        margin-top: 2rem;
    }
    .licence-line2{
        margin-left: 20px;
    }
    .licence-img{
        width: 23%;
    }
  
}

@media all and (max-width : 370px){
    .home-issn{
        margin-left: 180px;
    }
    .home-heading{
        font-size: medium;
    }
    .home-contents1{
        font-size: medium;
    }
    .licence-img{
        width: 40%;
        margin-top: 5rem;
        margin-bottom: 10%;
        margin-left: 20%;
    }
    .licence-line1{
        margin-left: 20px;
        margin-top: 1rem;
    }
    .licence-line2{
        margin-left: 20px;
    }
   
}