.card1{
  
     margin-top: 7%;
     font-family:'Times New Roman';
     font-size: large;
}
.card2{
    margin-top: 10%;
    
}
.sub-para{
    justify-content: center;
    margin-top: 4%;
    font-style:inherit;
    font-size:large;
    font-family:'Times New Roman';

}
.sub-heading3{
    margin-top: 10%;
    text-align: center;
}
.word-limit{
    margin-top: 10%;
    font-family:'Times New Roman';
    font-style:inherit;
    font-size:large
}
.card-header1{
    background-color: dimgray;
    color: floralwhite;
    text-align: center;
    font-size:x-large;
    font-family:'Times New Roman';
    

}
.card-header2{
    background-color: dimgray;
    color: floralwhite;
    text-align: center;
    font-size:x-large;
    font-family:'Times New Roman';
}
.sub-heading2{
    font-family:'Times New Roman';
    text-align: center;
    margin-top: 10%;


}
.sub-para1{
    font-family:'Times New Roman';
   font-Size:large;
   font-Style:inherit;
   margin-top: 4%;
}
.sub-word-limit{
    font-family:'Times New Roman';
    text-align: center;

}
.sub-word-limit1{
    font-family:'Times New Roman';
    text-align: center;
    margin-top: 4%;


}
@media all and (max-width: 370px) {
    .sub-heading3{
        margin-top: 5rem;
        font-size: x-large;
    }
    .sub-word-limit{
        font-size: x-large;
        margin-bottom: 10%;
    }
    .sub-word-limit1{
        margin-top:3rem;
        font-size: medium;
    }
    .sub-heading2{
        font-size: x-large;
    }
}
@media all and (max-width: 470px) {
.card1{
    margin: auto;
    margin-top: 6%;
    
}
.sub-para{
    margin-top: 9%;
}
.sub-para1{
    margin-top: 9%;
}
.card2{
    margin-bottom: 10%;
}
}