.login-card-body{
    margin-left: 37%;
    width: 18rem;
    margin-top: 15%;
    background-color: rgb(128, 113, 113);

}

.login-form{
    color: rgb(241, 227, 227);
}
@media all and (max-width: 700px) {
    .login-card-body{

        padding-left: 6px;
        justify-content: center;
        
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 15%;
      
   

    }
}
@media all and (max-width: 470px) {
    .login-card-body{

        padding-left: 6px;
        justify-content: center;
        
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 15%;
      
   

    }
}